exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-booking-index-js": () => import("./../../../src/pages/booking/index.js" /* webpackChunkName: "component---src-pages-booking-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cookies-index-js": () => import("./../../../src/pages/legal/cookies/index.js" /* webpackChunkName: "component---src-pages-legal-cookies-index-js" */),
  "component---src-pages-legal-notice-index-js": () => import("./../../../src/pages/legal/notice/index.js" /* webpackChunkName: "component---src-pages-legal-notice-index-js" */),
  "component---src-pages-legal-privacy-index-js": () => import("./../../../src/pages/legal/privacy/index.js" /* webpackChunkName: "component---src-pages-legal-privacy-index-js" */),
  "component---src-pages-menus-carta-index-js": () => import("./../../../src/pages/menus/carta/index.js" /* webpackChunkName: "component---src-pages-menus-carta-index-js" */),
  "component---src-pages-menus-desserts-index-js": () => import("./../../../src/pages/menus/desserts/index.js" /* webpackChunkName: "component---src-pages-menus-desserts-index-js" */),
  "component---src-pages-menus-index-js": () => import("./../../../src/pages/menus/index.js" /* webpackChunkName: "component---src-pages-menus-index-js" */),
  "component---src-pages-menus-infusions-index-js": () => import("./../../../src/pages/menus/infusions/index.js" /* webpackChunkName: "component---src-pages-menus-infusions-index-js" */),
  "component---src-pages-menus-menu-festivities-2-index-js": () => import("./../../../src/pages/menus/menuFestivities2/index.js" /* webpackChunkName: "component---src-pages-menus-menu-festivities-2-index-js" */),
  "component---src-pages-menus-menu-festivities-index-js": () => import("./../../../src/pages/menus/menuFestivities/index.js" /* webpackChunkName: "component---src-pages-menus-menu-festivities-index-js" */),
  "component---src-pages-menus-menu-group-index-js": () => import("./../../../src/pages/menus/menuGroup/index.js" /* webpackChunkName: "component---src-pages-menus-menu-group-index-js" */),
  "component---src-pages-menus-menu-index-js": () => import("./../../../src/pages/menus/menu/index.js" /* webpackChunkName: "component---src-pages-menus-menu-index-js" */),
  "component---src-pages-menus-wines-index-js": () => import("./../../../src/pages/menus/wines/index.js" /* webpackChunkName: "component---src-pages-menus-wines-index-js" */)
}

